/* global config, data, is_persona */
import { COMPANY_TYPE_MAP } from 'constants/companies';
import workspaceStatus from 'constants/workspaces';
import { PRICING_PLANS_READABLE } from 'constants/pricing';
import moment from 'moment';

import Analytics from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import intercomPlugin from '@analytics/intercom';
import { datadogRum } from '@datadog/browser-rum';

export function shouldTrack() {
  if (!config || !config.ANALYTICS_ENABLED) return false;
  if (window._DATADOG_SYNTHETICS_BROWSER) return false; // eslint-disable-line
  if (data.user && data.user.sample) return false;
  if (is_persona) return false;

  return true;
}

const mixpanelIdentifyEnricherPlugin = {
  name: 'enrich-mixpanel-identify',
  'identify:mixpanel': ({ payload }) => {
    const enrichedTraits = { ...payload.traits };

    enrichedTraits.$email = enrichedTraits.email;
    enrichedTraits.$first_name = enrichedTraits.first_name;
    enrichedTraits.$last_name = enrichedTraits.last_name;

    delete enrichedTraits.email;
    delete enrichedTraits.first_name;
    delete enrichedTraits.last_name;

    return { ...payload, traits: enrichedTraits };
  },
};

const intercomIdentifyEnricherPlugin = {
  name: 'enrich-intercom-identify',
  'identify:intercom': ({ payload }) => {
    const { user } = data;
    const enrichedTraits = { ...payload.traits };

    enrichedTraits.name = `${enrichedTraits.first_name} ${enrichedTraits.last_name}`;
    enrichedTraits.created_at = moment(user.date_joined).unix();

    enrichedTraits.user_id = enrichedTraits.id;

    return { ...payload, traits: enrichedTraits };
  },
};

const plugins = shouldTrack() ? [
  mixpanelIdentifyEnricherPlugin,
  mixpanelPlugin({
    token: config.MIXPANEL_PROJECT_ID,
    // Generally, I am not crazy about ignoring DNT. But it seems that even google may
    // not honor the DNT. I also do not recall setting that toggle, but it's set in mine,
    // so I'm not confident the default in the docs is correct
    // https://support.google.com/chrome/answer/2790761?co=GENIE.Platform%3DDesktop&hl=en
    options: {
      ignore_dnt: true,
      debug: false,
    },
    pageEvent: 'Page View',
  }),
  intercomIdentifyEnricherPlugin,
  intercomPlugin({
    appId: config.INTERCOM_APP_ID,
  }),
] : [];

const analytics = Analytics({
  app: 'Baton',
  debug: true,
  plugins,
});

function getCompanyData() {
  const companyData = {};
  if (data.user) {
    const { user } = data;

    if (user.company) {
      companyData.company = user.company.name;
      companyData.company_id = user.company.id;
      companyData.company_type = COMPANY_TYPE_MAP[user.company.type];
    }
  }

  if (data.workspace) {
    if (data.workspace) {
      companyData.workspace = data.workspace.slug;
      companyData.workspace_id = data.workspace.id;
      companyData.workspace_status = workspaceStatus[data.workspace.status];
      companyData.workspace_internal_testing = data.workspace.internal_testing;
      companyData.workspace_pricing_plan = PRICING_PLANS_READABLE[data.workspace.pricing_plan];

      if (data.workspace.vendor) {
        companyData.workspace_vendor = data.workspace.vendor.name;
        companyData.workspace_vendor_id = data.workspace.vendor.id;
      }
    }
  }

  return companyData;
}

function getUserData() {
  const userData = {};
  let allData = {};

  if (data.user) {
    const { user } = data;

    userData.id = user.id;
    userData.email = user.email;
    userData.first_name = user.first_name;
    userData.last_name = user.last_name;
    userData.job_title = user.job_title;
    userData.batonadmin = user.is_staff;
    userData.workspace_admin = user.workspace_admin;
    userData.sample = data.user.sample;
    userData.created_at = user.date_joined;

    const companyData = getCompanyData();

    allData = { ...userData, ...companyData };
  }

  return allData;
}

// eslint-disable-next-line no-unused-vars
function datadogShouldSendEvent(event, context) {
  // Do not send any events when tracking is disabled, because otherwise Rum is not initialized
  if (!shouldTrack()) return false;

  if (event.type === 'error') {
    const filters = [
      /Uncaught "Object Not Found Matching Id:(\d+)"/, // Errors generated by CefSharp, the browser framework Outlook uses for "Safe Links" scanning: https://github.com/getsentry/sentry-javascript/issues/3440
      /ResizeObserver loop .*/,
      /Script error\./, // For some reason, usually from Safari, but we expect this is caused by the blocking of some of our 3rd party tracking embeds
      /.*LPContentScriptFeatures.*/, // Safari Extension errors
    ];

    return !filters.some(f => f.test(event.error.message));
  }

  return true;
}

function initDatadog() {
  datadogRum.init({
    applicationId: '3e04dc40-5ecc-4902-967b-0ad208dd7c0e',
    clientToken: 'pub96ac4aad94bc89ee4ddd8719ebeee580',
    site: 'datadoghq.com',
    service: 'web-ui',
    env: config.ENVIRONMENT,
    version: config.RELEASE,
    allowedTracingOrigins: [
      /https:\/\/.*\.baton\.lan/,
      /https:\/\/.*\.hellobaton-demo\.com/,
      /https:\/\/.*\.hellobaton-temp\.com/,
      /https:\/\/.*\.hellobaton-stage\.com/,
      /https:\/\/.*\.hellobaton\.com/,
    ],

    // create events for user actions within a page, like which buttons they click
    trackInteractions: true,

    // reject some events
    beforeSend: datadogShouldSendEvent,

    // the percentage of sessions to track in RUM. 100 for all, 0 for none.
    sampleRate: 100,

    /* the percentage of sessions to record replays for. 100 for all, 0 for none. These are billed
    at the Replay pricing. */
    replaySampleRate: 100,

    // do not redact what users enter into forms, otherwise the replays aren't as useful
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();

  if (data.user) {
    const { user } = data;
    if (user && user.sample) return;

    const userData = getUserData();
    userData.name = `${user.first_name} ${user.last_name}`;

    datadogRum.setUser(userData);
  }
}

export function initAnalytics() {
  if (shouldTrack()) {
    initDatadog();

    // analytics.io requires the userId to be a string.
    // https://getanalytics.io/api/#analyticsidentify
    if (data.user) {
      const userData = getUserData();

      analytics.identify(String(userData.id), userData);
    }
  }
}

export function trackLocation(location) {
  if (location) {
    analytics.page({
      host: window.location.host,
      path: `${location?.pathname}${location?.search}${location?.hash}`,
      pathname: location?.pathname ?? '',
      search: location?.search ?? '',
      hash: location?.hash ?? '',
      href: window.location.href,
      workspace: data?.workspace?.slug,
      workspace_id: data?.workspace?.id,
      workspace_status: workspaceStatus?.[data?.workspace?.status],
      workspace_internal_testing: data?.workspace?.internal_testing,
      workspace_pricing_plan: PRICING_PLANS_READABLE?.[data?.workspace?.pricing_plan],
      company: data?.user?.company?.name,
      company_id: data?.user?.company?.id,
      company_type: COMPANY_TYPE_MAP?.[data?.user?.company?.type],
    });
  }
}

export function trackEvent(event, eventData = {}, user_id = undefined) {
  let trackingData = {};

  if (data.user) {
    trackingData = getCompanyData();
  }

  if (!shouldTrack()) return;

  if (user_id) {
    analytics.identify(user_id);
  }

  analytics.track(event, { ...trackingData, ...eventData, is_persona });
}
