/* eslint-disable no-param-reassign */
const randomFromTo = (from, to) => Math.floor(Math.random() * (to - from + 1) + from);

const Confetti = (props) => {
  const { context, width, height, maxParticles, colors, onAnimationComplete } = props;
  let particles = [];
  let angle = 0;
  let tiltAngle = 0;
  let confettiActive = true;
  let animationComplete = true;
  let animationHandler;

  const stepParticle = (particle, particleIndex) => {
    const { cos, sin } = Math;

    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.y += (cos(angle + particle.d) + 3 + particle.r / 2) / 2;
    particle.x += sin(angle);
    particle.tilt = (sin(particle.tiltAngle - (particleIndex / 3))) * 15;
  };

  const repositionParticle = (particle, xCoordinate, yCoordinate, tilt) => {
    particle.x = xCoordinate;
    particle.y = yCoordinate;
    particle.tilt = tilt;
  };

  const checkForReposition = (particle, index) => {
    const { random, floor, sin } = Math;

    if ((particle.x > width + 20 || particle.x < -20 || particle.y > height) && confettiActive) {
      // 66.67% of the flakes
      if (index % 5 > 0 || index % 2 === 0) {
        repositionParticle(particle, random() * width, -10, floor(random() * 10) - 20);
      } else if (sin(angle) > 0) {
        // Enter from the left
        repositionParticle(particle, -20, random() * height, floor(random() * 10) - 20);
      } else {
        // Enter from the right
        repositionParticle(particle, width + 20, random() * height, floor(random() * 10) - 20);
      }
    }
  };

  const stopConfetti = () => {
    animationComplete = true;
    context.clearRect(0, 0, width, height);
    window.cancelAnimationFrame(animationHandler);
    onAnimationComplete();
  };

  const update = () => {
    let remainingFlakes = 0;
    angle += 0.01;
    tiltAngle += 0.1;

    particles.forEach((particle, i) => {
      if (animationComplete) return;

      const newParticle = particle;

      if (!confettiActive && newParticle.y < -15) {
        newParticle.y = height + 100;
        return;
      }

      stepParticle(newParticle, i);

      if (newParticle.y <= height) remainingFlakes += 1;

      checkForReposition(newParticle, i);
    });

    if (remainingFlakes === 0) {
      stopConfetti();
    }
  };

  function ConfettiParticle(color) {
    const { random, floor } = Math;
    this.x = random() * width; // x-coordinate
    this.y = (random() * height) - height; // y-coordinate
    this.r = randomFromTo(10, 30); // radius;
    this.d = (random() * maxParticles) + 10; // density;
    this.color = color;
    this.tilt = floor(random() * 10) - 10;
    this.tiltAngleIncremental = (random() * 0.07) + 0.05;
    this.tiltAngle = 0;

    this.draw = () => {
      context.beginPath();
      context.lineWidth = this.r / 2;
      context.strokeStyle = color;
      context.moveTo(this.x + this.tilt + (this.r / 4), this.y);
      context.lineTo(this.x + this.tilt, this.y + this.tilt + (this.r / 4));

      return context.stroke();
    };
  }

  const draw = () => {
    context.clearRect(0, 0, width, height);
    update();

    return particles.map(particle => particle.draw());
  };

  const startConfetti = () => {
    const animLoop = () => {
      if (animationComplete) return null;
      animationHandler = window.requestAnimationFrame(animLoop);
      return draw();
    };

    animLoop();
  };

  const initializeConfetti = () => {
    const numArray = Array.apply(0, Array(maxParticles));

    particles = numArray.map((_, index) => new ConfettiParticle(colors[index % colors.length]));
    animationComplete = false;
    startConfetti();
  };

  const deactivateConfetti = () => {
    confettiActive = false;
  };

  const restartConfetti = () => {
    confettiActive = true;
    animationComplete = false;
    initializeConfetti();
  };

  return {
    restartConfetti,
    deactivateConfetti,
  };
};

export default Confetti;
