export default {
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    pointerEvents: 'none',
    zIndex: 4,
  },
};
