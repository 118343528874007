import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setConfettiPartyOver } from 'actions/ui';
import Confetti from './ConfettiCanvas';

function AppConfetti() {
  const dispatch = useDispatch();
  const party = useSelector(state => state.ui.confetti.party);
  const props = useSelector(state => state.ui.confetti.props);

  return (
    <Confetti
      party={party}
      onPartyIsOver={() => dispatch(setConfettiPartyOver())}
      {...props}
    />
  );
}

export default AppConfetti;
